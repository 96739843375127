<template>
	<div>
		<web-header></web-header>

		<div class="atContent">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
			<div class="search">
				<el-input placeholder="请输入教师名称" class="atInput" v-model="search" @change="searchHandle" clearable>
					<i slot="prefix" class="el-input__icon el-icon-search"></i>

				</el-input>
				<el-button @click="searchHandle">确定</el-button>
			</div>
			<div class="atInfo" v-if="list.length > 0">
				<div style="margin-bottom: 68px;">
					<div v-for="(item,idx) in list" :key="idx" class="infoItem">
						<div>
							<img :src="item.photo" alt="" class="portrait">
						</div>
						<div class="info">
							<div style="font-weight:bold;margin-bottom:20px">
								<span style="font-size: 18px">{{item.teacherName}} </span>
								<span>({{item.rank}})</span>
							</div>
							<p class="introduction">{{item.intro}}</p>
						</div>
					</div>
				</div>
				<div style="padding-bottom:28px;text-align: center;">
					<el-pagination background @current-change="handleCurrentChange" :current-page="page"
						:page-size="pageSize" layout="prev, pager, next" :total="total">
					</el-pagination>
				</div>
			</div>
			<div class="noTeacter" v-else-if="list.length === 0">
				<div class="noTeacter_img">
					<img src="../../assets/imgs/4.png" alt="">
					<div>抱歉，暂时没有您想要的内容</div>
				</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "权威师资"],
				imgList: [{
						url: ''
					},
					{
						url: '1'
					},
					{
						url: '2'
					},
				],
				search: '',
				list: [],
				page: 1,
				pageSize: 5,
				total: null
			}
		},
		mounted() {
			this.getByPage()
		},
		methods: {

			getByPage() {
				let data = {
					pageNumber: this.page,
					pageSize: this.pageSize,
					name: this.search
				}
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.$http.post('teachersGetByPage', data).then((res) => {
					if (res.code == 200) {
						this.list = res.data.records
						this.total = res.data.total
					} else {
						this.$message.error(res.message);
					}
					loading.close();
				})
			},
			searchHandle() {
				this.page = 1
				this.getByPage()
			},
			// 用户点击上一个
			previous() {
				console.log(111)
			},
			// 用户点击下一个
			next() {
				console.log(122)
			},
			handleCurrentChange(e) {
				this.page = e
				this.getByPage()
			}
		}
	}
</script>

<style lang="less" scoped>
	.atContent {
		width: 1200px;
		margin: auto;

		.search {
			margin-bottom: 23px;

			.atInput {
				width: 493px;
				height: 49px;
				margin-right: 30px;

				/deep/ .el-input__inner {
					height: 49px !important;
					text-indent: 75px;
				}

				.el-input__icon {
					line-height: 49px;
					width: 145px;
				}
			}

			/deep/ .el-button {
				background-color: #E51E0B !important;
				color: white;
				height: 49px !important;

			}
		}

		.atInfo {
			width: 100%;
			box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);

			.infoItem {
				padding-left: 41px;
				padding-top: 36px;
				padding-bottom: 30px;
				border-bottom: 1px solid #EEEEEE;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 14px;
				color: #333333;

				.info {
					width: 1096px;
					padding-left: 20px;
					padding-right: 20px;
				}

				.portrait {
					width: 112px;
					height: 112px;
					border-radius: 50%;
				}
			}

			.fy {
				/deep/ .active {
					background: #E51E0B !important;
				}

				/deep/ .el-pager li {
					width: 43px;
					height: 43px;
					line-height: 43px;
					font-size: 14px;
				}

				/deep/ .btn-prev {
					width: 43px;
					height: 43px;
					line-height: 43px;
					font-size: 14px;
				}

				/deep/ .btn-next {
					width: 43px;
					height: 43px;
					line-height: 43px;
					font-size: 14px;
				}
			}
		}
	}

	/deep/.el-carousel__button {
		background: #E51E0B;
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}

	.introduction {
		// width: 300px;
		height: 100px;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		/** 对象作为伸缩盒子模型显示 **/
		-webkit-box-orient: vertical;
		/** 设置或检索伸缩盒对象的子元素的排列方式 **/
		-webkit-line-clamp: 5;
		/** 显示的行数 **/
		overflow: hidden;
		/** 隐藏超出的内容 **/
	}
</style>
